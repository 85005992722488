<template>
	<div class="o-layout o-layout--medium">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<title-text :title="EMAILSETTINGS.Title" class="qa-mail-settings-title" />
				</div>
			</div>

			<div class="row">
				<div v-for="(userRole, userRoleindex) in filteredRoles" :key="userRoleindex" class="col-12">
					<div class="mb-5 u-bordered-rows">
						<div class="d-flex justify-content-between align-items-center">
							<h3 class="qa-user-role m-0">{{ userRole.displayRole }}</h3>
							<span v-if="hasEmailTypes(userRole.displayEmailType)" class="u-fw-bold">{{ EMAILSETTINGS.emailUnsubscribe }}</span>
						</div>

						<div v-for="(displayEmail, emailIndex) in userRole.displayEmailType" :key="emailIndex" class="mt-3 pt-3">
							<div class="d-flex justify-content-between align-items-center">
								<h4 v-if="hasEmailType(displayEmail.emailType)" class="qa-user-role mb-0">
									{{ displayEmail.displayValue }}
								</h4>
								<span v-else class="qa-user-role">{{ displayEmail.displayValue }}</span>

								<recess-toggle-input
									v-if="hasEmailType(displayEmail.emailType)"
									:value="!displayEmail.isActive"
									:name="`email-${displayEmail.emailType}`"
									:class="`_box-sizing--content-box email-${emailIndex}`"
									@input="(newValue) => toggleEmailStatus(displayEmail, newValue)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
 
<script>
import { getItems, postItem, deleteItem } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS, EMAILSETTINGS, ROLES, DEFAULT } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../shared/services/UserService'
import { defaultErrorMessage } from '../../../shared/constants/validationMessageHelper'

const TitleText = () => import('@/components/atoms/TitleText')

export default {
	name: 'EmailSettings',
	components: {
		TitleText
	},
	data() {
		return {
			userService: new UserService(),
			EMAILSETTINGS,
			DEFAULT,
			ROLES,
			filteredRoles: [],
			currentUserId: null
		}
	},
	async mounted() {
		this.currentUserId = await this.userService.getUserId()
		await this.getRoleEmails()
		await this.getEmailCancellationSubscription()
	},
	methods: {
		hasEmailType(value) {
			return value !== null
		},
		hasEmailTypes(value) {
			return value.length > 1
		},
		async getUserRoles() {
			const userRoles = await this.userService.getUserRoles()

			this.filteredRoles = userRoles.reduce((acc, el) => {
				const existingRoles = this.ROLES.filter((role) => {
					if (el === role.type) {
						role.displayEmailType = []
						return role
					}

					return null
				})

				return [...acc, existingRoles[0]]
			}, [])
		},
		async getRoleEmails() {
			await this.getUserRoles()

			try {
				const response = await getItems(process.env.VUE_APP_EMAIL_API_URL, API_CALL_URL_PATHS.emailTypeRoles, 1, 100, '', '')
				if (!response) return
				const roleEmails = response.items

				for (const userRole of this.filteredRoles) {
					let composeEmailType = {}

					roleEmails.filter((item) => {
						if (userRole.type === item.role) {
							composeEmailType = {
								emailType: item.emailType,
								displayValue: item.emailTypeDisplayValue
							}
							userRole.displayEmailType = [...userRole.displayEmailType, composeEmailType]
						}
					})

					if (userRole.displayEmailType.length === 0) {
						composeEmailType = {
							emailType: null,
							displayValue: EMAILSETTINGS.ComposeEmailType.DisplayValue
						}

						userRole.displayEmailType = [composeEmailType]
					}
				}
			} catch (error) {
				console.error('Something went wrong while retrieving email type roles list', error)
			}
		},
		// --- Retrieve emailCancellations and map each cancellation ID to each Role email type
		async getEmailCancellationSubscription() {
			try {
				const response = await getItems(
					process.env.VUE_APP_EMAIL_API_URL,
					API_CALL_URL_PATHS.emailSubscriptionCancellations,
					1,
					99999,
					'',
					''
				)
				if (!response) return

				this.filteredRoles = this.filteredRoles.reduce((acc, role) => {
					role.displayEmailType.reduce((acc2, roleEmailType) => {
						const matchCancellationEmailType = response.items.filter((cancellationEmail) => {
							if (roleEmailType.emailType === cancellationEmail.emailType) {
								roleEmailType.isActive = true
								return cancellationEmail
							}
							return null
						})

						if (matchCancellationEmailType.length) {
							roleEmailType.id = matchCancellationEmailType[0].id

							return [...acc2, matchCancellationEmailType]
						}

						return acc2
					}, [])

					return [...acc, role]
				}, [])
			} catch (error) {
				console.error('Something went wrong while retrieving email cancellation subscruption list', error)
			}
		},
		// OPT-IN
		// Deleting the Email Cancellation Subscription means the user
		// is OPTING BACK IN and would like to receive the e-mail again.
		// An Email Cancellation Subscription object basically represents
		// that you want to cancel the subscription (e.g. don't get anything)
		async deleteEmailCancellationSubscription(displayEmail) {
			await deleteItem(process.env.VUE_APP_EMAIL_API_URL, `${API_CALL_URL_PATHS.emailSubscriptionCancellations}/${displayEmail.id}`, false)
				.then(() => {
					const toastNotification = {
						type: 'success',
						message: this.EMAILSETTINGS.successMessage.optInMessage
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					const toastNotification = {
						type: 'error',
						message: defaultErrorMessage
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})

					console.error('Something went wrong while deleting email cancellation', error)
				})
		},
		// OPT-OUT
		// Creating the Email Cancellation Subscription means the user
		// is OPTING OUT and no longer wants to receive the e-mail.
		// An Email Cancellation Subscription object basically represents
		// that you want to cancel the subscription (e.g. don't get anything)
		async postEmailCancellationSubscription(postObject) {
			await postItem(process.env.VUE_APP_EMAIL_API_URL, API_CALL_URL_PATHS.emailSubscriptionCancellations, postObject)
				.then((response) => {
					if (!response) return

					const toastNotification = {
						type: 'success',
						message: this.EMAILSETTINGS.successMessage.optOutMessage
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					const toastNotification = {
						type: 'error',
						message: defaultErrorMessage
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})

					console.error('Something went wrong while posting email cancellation', error)
				})
		},
		async toggleEmailStatus(displayEmail, isSelected) {
			displayEmail.isActive = isSelected

			const postObject = {
				aspNetUserReference: `user:${this.currentUserId}`,
				emailType: displayEmail.emailType
			}

			if (!isSelected) {
				displayEmail.isActive = true
				await this.postEmailCancellationSubscription(postObject)
			} else {
				displayEmail.isActive = false
				await this.deleteEmailCancellationSubscription(displayEmail)
			}

			await this.getEmailCancellationSubscription()
		}
	}
}
</script>

